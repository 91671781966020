<template>
  <div>
    <v-form
      ref="password_form"
      v-model="password_valid"
      lazy-validation>
      <v-card elevation="0" class="mx-10 px-10">
        <v-card-text>
          <h1 class="text-h3 text-center py-5">Set Password</h1>
        </v-card-text>
        <v-card-text>
          <v-container fluid>
            <v-row dense>
              <v-col cols="8" offset="2" class="text-center">
                <p>This password is being set for <b>{{email}}</b></p>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="8" offset="2">
                <v-text-field
                  type="password"
                  outlined
                  v-model="password_form.fields.password"
                  :rules="[$utilities.rules.required, $utilities.rules.min]"
                  label="Password"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="8" offset="2">
                <v-text-field
                  type="password"
                  outlined
                  v-model="password_form.fields.confirm"
                  :rules="[$utilities.rules.required, $utilities.rules.min, rules.mustMatch]"
                  label="Confirm"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col align="center">
                <v-btn color="primary" dark x-large @click="passwordSave">
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>  
    </v-form>
  </div>
</template>

<script>
export default {
    name: 'Password.vue',
    data() {
      return {
        email: '',
        password_valid: false,
        password_form:{
          fields: {
            password: '',
            confirm: '',
          },
        },
        rules: {
          mustMatch: v => this.password_form.fields.password === v || 'Passwords must match',
        },
      }
    },
    async created(){
      let self = this;
      await this.$store.dispatch('users/tokenExists',{
        'token': this.$route.params.token
      })
      .then( (results) =>{
        if(!results.data.length > 0){
          self.$router.push('/login')
        }
      });
      await this.$store.dispatch('users/byToken',{
        'token': this.$route.params.token
      })
      .then( (results) =>{
        if(results.data[0]){
          self.email = results.data[0].email;
        }
      });
    },
    methods:{
      passwordSave(){
        let self = this;
        if(this.$refs.password_form.validate()){
          this.$store.dispatch('users/passwordForgot', {
            'password': self.password_form.fields.password,
            'token': self.$route.params.token
          })
          .then(() =>{
            setTimeout(() => {
              self.$router.push({ name: 'Login' });
            },2000)
          })
        }
      }
    }
  }
</script>

<style lang="scss">
</style>